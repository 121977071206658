<template>

  <div>

    <b-card no-body>
      <b-card-header>
            
        <div>
          <b-card-title class="mb-1">
            Weekly Forms
          </b-card-title>
          
        </div>
        <!--/ title and subtitle -->

        <!-- datepicker -->
        <div class="d-flex align-items-center">
          <feather-icon
            icon="CalendarIcon"
            size="32"
          />
          
          <flat-pickr
            v-model="date_range"
            :config="date_config"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="DD MMM YYYY"
            style="width:300%"
            @on-change="filterTable"

          />

        </div>
            <!-- datepicker -->
      </b-card-header>
      <b-card-body>

        <b-table sticky-header
          ref="refUserListTable"
          class="position-relative"
          :items="dataList"
          responsive
          :fields="tableColumns"
          show-empty
          empty-text="No records found"
        >

          <template #cell(form_name)="items">
             
              <p class="wordBreak">{{ items.item.form_name | capitalize}}</p>
           
          </template>

          <template #cell(status)="items">

              <feather-icon icon="CheckCircleIcon" class="mediumSize ml-1" v-if="items.item.status" style="width:22px;height: 22px; color:#33ff33" />
              <feather-icon icon="XIcon" class="mediumSize ml-1" v-else style="width:22px;height: 22px;color:#ff1a1a" />

          </template>

        </b-table>
      </b-card-body>
    </b-card>


  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody,BFormCheckbox,BCardHeader,BCardTitle
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { POST_API } from "../../../store/actions.type";
import Bus from "../../../event-bus";
import flatPickr from 'vue-flatpickr-component';
import moment from "moment-timezone";

export default {
  props:["siteData","form_type"],
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb,
    flatPickr,
    BCardHeader,
    BCardTitle
  },
  data() {
    return {
       tableColumns: [
            { key: 'form_name', label: 'Form Name', sortable: false , thStyle:  {width: '70%'}},
            { key: 'status', label: 'Status', sortable: false , thStyle:  {width: '30%'}},
            
          ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,

        date_config:{ 
         
          mode: 'range',
          dateFormat: 'd M Y',
          defaultDate: [
            moment.tz('Asia/Singapore').startOf('week').format('DD MMM YYYY'),
            moment.tz('Asia/Singapore').endOf('week').format('DD MMM YYYY')
          ]
        },

        date_range:moment.tz('Asia/Singapore').startOf('week').format('DD MMM YYYY') +' to '+moment.tz('Asia/Singapore').endOf('week').format('DD MMM YYYY'),
    }
  },
  methods : {
    dataList(){

      return this.$store.dispatch(POST_API, {
         data:{
           role:this.$store.getters.currentUser.role,
           om_sites:this.$store.getters.currentUser.om_sites,
           site:this.siteData ? this.siteData._id : null,
           form_type:this.form_type,
           range:this.date_range
         },
         api: '/api/custom-form-dashboard'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.items = this.$store.getters.getResults.data;
              this.$emit('weeklyCount', this.items.length);
              return this.items;
          }
      });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    }
  },
  mounted(){
    Bus.$on('refreshData', (data) => {
        this.filterTable();
    })
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
