<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card no-body>
        <b-card-body>
            <b-row>
                <b-col cols="12" md="4" class="mb-md-0 mobile_tab_margin_bottom_point_five mobile_tab_max_width_flex ">

                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="sites"
                    label="site_name"
                    class="w-100"
                    placeholder="Project Site"
                    v-model="siteData"
                    @input="updateSite()"
                    :clearable="false"
                  >
                    <template #selected-option="{ site_name }">
                      <span v-if="site_name.length < 23">{{site_name}}</span>
                      <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                    </template>
                  </v-select>


                </b-col>
                <b-col md="8" class="mobile_tab_max_width_flex ">

                    <div class="demo-inline-spacing float-right margin_bottom_zero_mobile">
                    
                      <b-button
                        variant="warning"
                        class="mt-0  margin_bottom_zero_mobile"
                        :to="{name:'off-reports'}"
                      >
                        <feather-icon
                            icon="SettingsIcon"
                            class="mr-25"
                          />
                          <span>Visible Settings</span>
                        </b-badge>
                      </b-button>

                      <b-button
                        variant="danger"
                        class="mt-0 common_margin_right_zero margin_bottom_zero_mobile"
                        :to="{name:'custom-forms-reports'}"
                      >
                      <feather-icon
                          icon="FileTextIcon"
                          class="mr-25"
                        />
                        <span>All Forms</span>
                      </b-badge>
                    </b-button>
                  </div>
              </b-col>

            </b-row>
        </b-card-body>
    </b-card>
    <b-row class="match-height" v-if="siteData != null">
      <b-col cols="12" md="6" class="mobile_tab_max_width_flex">

        <Daily :siteData="siteData" form_type="daily" v-if="show_daily" @dailyCount="dailyCount" />

      </b-col>

      <b-col cols="12" md="6" class="mobile_tab_max_width_flex">

        <Weekly :siteData="siteData" form_type="weekly" v-if="show_weekly" @weeklyCount="weeklyCount" />
        
      </b-col>
    
      <b-col cols="12" md="6" class="mobile_tab_max_width_flex"> 

        <Adhoc :siteData="siteData" form_type="adhoc" v-if="show_adhoc" @adhocCount="adhocCount"/>

      </b-col>

      <b-col cols="12" md="6" class="mobile_tab_max_width_flex">

        <Monthly :siteData="siteData" form_type="monthly" v-if="show_monthly" @adhocCount="monthlyCount"/>

      </b-col>
    </b-row>

      
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BForm,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BFormFile,BFormGroup, BFormCheckbox,BCardBody,BBreadcrumb
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import moment from "moment-timezone";
import Bus from "../../../event-bus";
import Daily from "../custom_form_dashboard/DailyDashboard";
import Weekly from "../custom_form_dashboard/WeeklyDashboard";
import Adhoc from "../custom_form_dashboard/AdhocDashboard";
import Monthly from "../custom_form_dashboard/MonthlyDashboard";

export default {
  components: {
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    vSelect,
    BAlert,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    BBreadcrumb,
    Daily,
    Weekly,
    Adhoc,
    Monthly
  },
  data() {
    return {
        webUrl: process.env.VUE_APP_SERVER_URL,
        showDismissibleAlert:false,
        formsList:[],
        siteData:null,
        sites:[],
        show_daily:true,
        show_weekly:true,
        show_adhoc:true,
        show_monthly:true
    }
  },
  methods : {

    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Reports',
      },{
        to:null,
        text:'Form Dashboard',
        active:true
      }];
      return item;
    },
    allSites(){
        return this.$store.dispatch(POST_API, {
            data:{
            role:this.$store.getters.currentUser.role,
            om_sites:this.$store.getters.currentUser.om_sites,
            },
            api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.sites = this.$store.getters.getResults.data;
                // if (this.sites.length == 1 ) {
                //     this.siteData = this.sites[0];
                //     this.updateSite();
                // }

                /*var obj = {
                  _id:'all-site',
                  site_name:'All Project Sites',
                  pdf_date_format: 'DD MMM YYYY',
                  pdf_time_format: 'HH:mm'
                }
                this.sites.unshift(obj);*/

                this.siteData = this.defaultSite(this.sites);
                this.updateSite();
                
                return this.sites;
            }
        });
    },
    updateSite(){
      Bus.$emit('refreshData');
    },
    dailyCount(value){
      if (value > 0) {
        this.show_daily = true;
      }else{
        this.show_daily = false;
      }
    },
    weeklyCount(value){
      if (value > 0) {
        this.show_weekly = true;
      }else{
        this.show_weekly = false;
      }
    },
    adhocCount(value){
      if (value > 0) {
        this.show_adhoc = true;
      }else{
        this.show_adhoc = false;
      }
    },
    monthlyCount(value){
      if (value > 0) {
        this.show_monthly = true;
      }else{
        this.show_monthly = false;
      }
    },
    
   
  },
  mounted(){
    if (this.checkPermission('custom-forms-dashboard', 'Dashboard')) {
      this.allSites();
      Bus.$emit('counter_update');
      //console.log('permission hai');
    }else{
      //console.log('ni hai');
      this.$router.push({name:'custom-forms-reports'});
    }
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
